import 'aos/dist/aos.css'

import "./App.css"

import logoHorizontal from "./images/logo-horizontal.jpg"
import gordan from "./images/gordan.jpg"
import kafa1 from "./images/kafa1.jpg"
import kafa2 from "./images/kafa2.jpg"
import kafa3 from "./images/kafa3.jpg"
import kafa4 from "./images/kafa4.jpg"
import kafa5 from "./images/kafa5.jpg"
import kafa6 from "./images/kafa6.jpg"
import osoblje from "./images/osoblje.jpeg"
import kolac from "./images/kolac.jpg"
import enterijer from "./images/enterijer/enterijer1.jpg"
export default function App() {
  return (
    <div className="app">
          <nav className="navbar">
            <h1>WELCOME</h1>
            <img src={logoHorizontal} alt="" className="nav-logo"/>
          </nav>

          <main className="main">

            <div className="page">
              <div className="coffee-beans" />
              <img src={gordan} alt="" className="gordan"/>
              <h1 className="dobrodosli">Dobrodošli u naš mali raj!</h1>
              <img src={logoHorizontal} alt="" className="logo-horizontal"/>
            </div>

            <div className="page">
              <h2 className="posjetite-nas">Posjetite nas na adresi:<br/>Majora Dragutina Gavrilovića 51, Bijeljina</h2>
              <img src={kafa5} alt="" className="siroka-slika karta-pica-kafa" />
              <h2 className="opustite-se">Opustite se i uživajte u ukusnim napicima koje pripremamo samo za vas.</h2>
              <div className="coffee-beans prva-grupa-slika">
                <img src={kafa1} alt="" />
                <img src={kafa4} alt="" className="poslednja-slika" />
              </div>
            </div>

            <div className="page">
              <h2 className="uzivajte">Uživajte u ugodnom enterijeru i mirnoj atmosferi našeg kafića, koji je više od mjesta za ispijanje kafe - to je mjesto gdje se stvaraju dragocjena prijateljstva.</h2>
              <img src={enterijer} alt="" className="enterijer"/>
              <h2 className="osoblje-text">Slobodno se prepustite našem ljubaznom osoblju koje će vas ugostiti s osmijehom i pobrinuti se da se osjećate kao kod kuće.</h2>
              <img src={osoblje} alt="" className="siroka-slika osoblje" />
            </div>

            <div className="page">
              <h2 className="kolac-text">Neka vas naša selekcija ukusnih kolača i slatkih zalogaja zavede u svijet slatkih užitaka.</h2>
              <img src={kolac} alt="" className="siroka-slika kolac" />
              <h2 className="oprez">Ali, oprez! Dolazak kod nas može izazvati neodoljivu naviku koju ćete rado njegovati, a naš kafić će postati vaše omiljeno mjesto za opuštanje i uživanje.</h2>
              <div className="coffee-beans druga-grupa-slika">
                <img src={kafa2} alt="" className="prva-slika" />
                <img src={kafa3} alt="" className="srednja-slika" />
                <img src={kafa6} alt="" className="poslednja-slika" />
              </div>
              <h1 className="vidimo-se">Vidimo se na kafi!</h1>
            </div>

          </main>
      </div>
  );
}
